/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/


import React, { useRef, useState, useEffect } from 'react'

import { useGLTF } from '@react-three/drei'
import { useTexture } from '@react-three/drei'

import {TweenMax , Power3} from 'gsap';

import useMousePosition from '../../hooks/useMousePosition'


const Model = React.forwardRef((props, ref) => {


  const mesh = useRef()

  
  const { nodes, materials } = useGLTF('/model/model.glb')
  const matcap = useTexture('/model/matcap_12.png')

  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);


  useEffect(() => {
    TweenMax.from(mesh.current.scale, 1 ,{x: 5, y:5 ,z:5, ease: Power3.easeOut} )
  }, [])

  const { x, y } = useMousePosition()
  const [modelRotation, setModelRotation] = useState({x: 0, y: 0})


   
    useEffect(()=>{
        const innerWidth = window.innerWidth
        const innerHeight = window.innerHeight
        setModelRotation({x: -(x - innerWidth/2) * 0.0002, y: (y - innerHeight/2) * 0.0004})
    },[x, y])



  const handlePointerOver = () => {
    TweenMax.to(mesh.current.material.color, 1 ,{r: 255, g:255 ,b:255, ease: Power3.easeOut})
  }

  const handlePointerOut = () => {
    TweenMax.to(mesh.current.material.color, 1 ,{r: 1, g:1 ,b:1,  ease: Power3.easeOut})
  }


  return (
    <group  ref={ref}  {...props} dispose={null}>
      
      <mesh
        ref = {mesh}
        geometry={nodes.MODEL001.geometry}
        rotation={[modelRotation.y + Math.PI/2 ,0,modelRotation.x]}
        scale={[12,12,12]}
        // onPointerOver={handlePointerOver}
        // onPointerOut={handlePointerOut}
      >
         <meshNormalMaterial />
      </mesh>
     
    </group>
  )
})


export default Model