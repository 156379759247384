import React from 'react'

const Logo = () => {
    return (
        <div className="header__logo">
                <svg width="1088" height="212" viewBox="0 0 1088 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                        <path d="M90.5031 27.5659L156.741 11.1572C160.02 10.5009 163.299 13.7826 161.331 17.0644L72.7961 192.309C72.1403 193.622 71.4844 194.278 70.1728 194.278L5.24694 210.03C1.96786 210.687 -1.31123 207.405 0.656225 204.123L87.8799 29.5349C88.5357 28.2222 89.1915 27.5659 90.5031 27.5659Z" fill="white"/>
                        <path d="M550.886 27.5655L616.468 10.5005C619.747 9.84415 623.026 13.1259 621.059 16.4076L533.179 192.309C532.523 193.621 531.868 194.278 530.556 194.278L465.63 210.03C462.351 210.686 459.072 207.405 461.039 204.123L547.607 29.5345C548.263 28.2219 549.575 27.5655 550.886 27.5655Z" fill="white"/>
                        <path d="M230.191 27.5659L296.429 11.1572C299.708 10.5009 302.987 13.7826 301.019 17.0644L219.698 178.526C219.042 179.838 218.386 180.495 217.075 180.495L151.493 196.903C148.214 197.56 144.935 194.278 146.902 190.996L227.568 29.5349C228.224 28.2222 229.535 27.5659 230.191 27.5659Z" fill="white"/>
                        <path d="M334.467 27.5655L400.048 10.5005C403.328 9.84415 406.607 13.1259 404.639 16.4076L316.76 192.309C316.104 193.621 315.448 194.278 314.136 194.278L249.211 210.03C245.931 210.686 242.652 207.405 244.62 204.123L331.843 29.5345C332.499 28.2219 333.155 27.5655 334.467 27.5655Z" fill="white"/>
                        <path d="M101.651 175.901L167.233 159.492C170.512 158.836 173.791 162.118 171.824 165.399L157.396 193.622C156.74 194.935 156.084 195.591 154.773 195.591L89.8468 211.344C86.5677 212 83.2886 208.718 85.2561 205.436L99.0282 177.87C99.6841 177.214 100.34 176.557 101.651 175.901Z" fill="white"/>
                        <path d="M326.597 158.178L392.179 141.77C395.458 141.113 398.737 144.395 396.769 147.677L382.341 175.9C381.686 177.212 381.03 177.869 379.718 177.869L314.792 193.621C311.513 194.277 308.234 190.996 310.202 187.714L323.974 160.147C324.63 158.835 325.941 158.178 326.597 158.178Z" fill="white"/>
                        <path d="M665.654 27.5659L731.892 11.1572C735.171 10.5009 738.45 13.7826 736.482 17.0644L647.947 192.309C647.291 193.622 646.636 194.278 645.324 194.278L580.398 210.03C577.119 210.687 573.84 207.405 575.807 204.123L663.031 29.5349C663.687 28.2222 664.343 27.5659 665.654 27.5659Z" fill="white"/>
                        <path d="M657.785 158.178L723.366 141.77C726.645 141.113 729.924 144.395 727.957 147.677L713.529 175.9C712.873 177.212 712.217 177.869 710.906 177.869L645.98 193.621C642.701 194.277 639.422 190.996 641.389 187.714L655.161 160.147C655.817 158.835 657.129 158.178 657.785 158.178Z" fill="white"/>
                        <path d="M708.282 16.4097L773.864 0.000981845C777.143 -0.655365 780.422 2.62637 778.454 5.9081L764.026 34.131C763.371 35.4437 762.715 36.1001 761.403 36.1001L696.477 52.5087C693.198 53.1651 689.919 49.8833 691.887 46.6016L705.659 19.035C706.315 17.7223 706.97 17.066 708.282 16.4097Z" fill="white"/>
                        <path d="M798.785 27.5659L865.023 11.1572C868.302 10.5009 871.581 13.7826 869.614 17.0644L781.078 192.309C780.422 193.622 779.767 194.278 778.455 194.278L713.529 210.03C710.25 210.687 706.971 207.405 708.938 204.123L796.162 29.5349C796.818 28.2222 797.474 27.5659 798.785 27.5659Z" fill="white"/>
                        <path d="M863.711 69.5738L929.948 52.5088C933.227 51.8524 936.507 55.1342 934.539 58.4159L867.646 191.654C866.99 192.967 866.334 193.623 865.022 193.623L800.097 210.032C796.818 210.688 793.539 207.407 795.506 204.125L861.743 71.5429C861.743 70.2302 863.055 69.5738 863.711 69.5738Z" fill="white"/>
                        <path d="M791.571 158.178L857.152 141.77C860.431 141.113 863.71 144.395 861.743 147.677L847.315 175.9C846.659 177.212 846.003 177.869 844.692 177.869L779.766 193.621C776.487 194.277 773.208 190.996 775.175 187.714L788.947 160.147C788.947 158.835 790.259 158.178 791.571 158.178Z" fill="white"/>
                        <path d="M841.412 16.4097L906.994 0.000981845C910.273 -0.655365 913.552 2.62637 911.585 5.9081L897.157 34.131C896.501 35.4437 895.845 36.1001 894.534 36.1001L830.264 52.5087C826.984 53.1651 823.705 49.8833 825.673 46.6016L839.445 19.035C839.445 17.7223 840.757 17.066 841.412 16.4097Z" fill="white"/>
                        <path d="M558.101 158.181L584.989 150.961C588.268 150.305 591.547 153.586 589.58 156.868L575.152 185.091C574.496 186.404 573.84 187.06 572.529 187.06L546.296 193.623C543.017 194.28 539.738 190.998 541.705 187.716L555.477 160.15C555.477 158.837 556.789 158.181 558.101 158.181Z" fill="white"/>
                        <path d="M491.863 65.6357L557.444 49.2271C560.723 48.5707 564.002 51.8524 562.035 55.1342L547.607 83.3571C546.951 84.6698 546.295 85.3261 544.984 85.3261L480.058 101.078C476.779 101.735 473.5 98.4531 475.467 95.1713L489.239 67.6048C489.895 66.9484 490.551 66.2921 491.863 65.6357Z" fill="white"/>
                        <path d="M405.295 65.6357L470.877 49.2271C474.156 48.5707 477.435 51.8524 475.467 55.1342L461.039 83.3571C460.384 84.6698 459.728 85.3261 458.416 85.3261L393.49 101.735C390.211 102.391 386.932 99.1094 388.9 95.8277L402.672 68.2611C402.672 66.9484 403.983 66.2921 405.295 65.6357Z" fill="white"/>
                        <path d="M421.691 106.985C422.347 105.672 423.002 105.016 424.314 104.36L524.654 79.4185L518.752 89.92C518.096 91.2327 517.44 91.889 516.128 91.889L417.1 116.174L421.691 106.985Z" fill="white"/>
                        <path d="M109.521 104.358C110.177 103.046 110.833 102.389 112.144 101.733L165.265 87.9497L159.363 98.4513C158.707 99.764 158.051 100.42 156.74 100.42L104.274 112.891L109.521 104.358Z" fill="white"/>
                        <path d="M285.28 41.3495C285.936 40.0368 286.592 39.3805 287.903 39.3805L341.024 25.5972L335.122 36.0987C334.466 37.4114 333.81 38.0678 332.499 38.0678L280.033 50.5383L285.28 41.3495Z" fill="white"/>
                        <path d="M358.731 101.077C359.387 99.7639 360.043 99.1076 361.354 98.4513L414.476 84.668L408.573 95.1695C407.917 96.4822 407.262 97.1386 405.95 97.1386L353.485 109.609L358.731 101.077Z" fill="white"/>
                        <path d="M413.821 126.02C414.476 124.707 415.132 124.05 416.444 123.394L469.565 109.611L463.663 120.112C463.007 121.425 462.351 122.081 461.039 122.081L408.574 134.552L413.821 126.02Z" fill="white"/>
                        <path d="M1016.52 27.5659L1082.75 11.1572C1086.03 10.5009 1089.31 13.7826 1087.34 17.0644L998.809 192.309C998.153 193.622 997.498 194.278 996.186 194.278L931.26 210.03C927.981 210.687 924.702 207.405 926.669 204.123L1013.89 29.5349C1014.55 28.2222 1015.86 27.5659 1016.52 27.5659Z" fill="white"/>
                        <path d="M958.149 65.6357L1023.73 49.2271C1027.01 48.5707 1030.29 51.8524 1028.32 55.1342L1013.89 83.3571C1013.24 84.6698 1012.58 85.3261 1011.27 85.3261L946.344 101.078C943.065 101.735 939.786 98.4531 941.753 95.1713L955.525 67.6048C955.525 66.9484 956.837 66.2921 958.149 65.6357Z" fill="white"/>
                        <path d="M887.976 106.985C888.632 105.672 889.287 105.016 890.599 104.36L990.939 79.4185L985.037 89.92C984.381 91.2327 983.725 91.889 982.413 91.889L883.385 116.174L887.976 106.985Z" fill="white"/>
                        <path d="M880.106 126.02C880.761 124.707 881.417 124.05 882.729 123.394L935.85 109.611L929.948 120.112C929.292 121.425 928.636 122.081 927.324 122.081L874.859 134.552L880.106 126.02Z" fill="white"/>
                        <path d="M169.201 65.6357L234.783 49.2271C238.062 48.5707 241.341 51.8524 239.373 55.1342L224.945 83.3571C224.29 84.6698 223.634 85.3261 222.322 85.3261L157.396 101.735C154.117 102.391 150.838 99.1094 152.806 95.8277L166.578 68.2611C167.234 66.9484 167.889 66.2921 169.201 65.6357Z" fill="white"/>
                        <path d="M31.4794 65.6357L97.0611 49.2271C100.34 48.5707 103.619 51.8524 101.652 55.1342L87.2238 83.3571C86.568 84.6698 85.9122 85.3261 84.6005 85.3261L19.6747 101.078C16.3956 101.735 13.1165 98.4531 15.084 95.1713L28.8561 67.6048C29.5119 66.9484 30.8236 66.2921 31.4794 65.6357Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="1088" height="212" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

        </div>
    )
}

export default Logo
