import * as React from "react"
import '../components/layout.css'
import Background from '../components/home/Background'
import Header from '../components/home/Header'


const IndexPage = () => {
  return (
    <main className="">
      <Background />
      <Header/>
    </main>
  )
}

export default IndexPage
