// import ReactDOM from 'react-dom'
import {TweenMax , Power3} from 'gsap';

import React, { Suspense, useRef, useState, useEffect } from 'react'

import { Canvas,  useThree } from 'react-three-fiber'



import { OrbitControls, useTexture } from '@react-three/drei'
import { useGLTF ,Text, MeshWobbleMaterial} from '@react-three/drei'

import Model from './Model'








const MyScene =() =>{
    


    const model = React.createRef()
  
    
 

    const [modelProps, setModelProps] = useState(
        {
            position:[0, 0.5, -1],
            rotation:[0, 0, 0]
        }
      )
      
    const scrollToScreen0 = () => {
        //Model
        TweenMax.to(model.current.position, 2 ,{x: 0, y:0.5 ,z:-1, ease: Power3.easeOut})
        TweenMax.to(model.current.rotation, 2 ,{x: 0, y: 0 ,z:0, ease: Power3.easeOut})

       
    }

    const scrollToScreen1 = () => {
        //Model
        TweenMax.to(model.current.position, 2 ,{x: -4, y:0 ,z:0, ease: Power3.easeOut})
        TweenMax.to(model.current.rotation, 2 ,{x: 0, y: Math.PI * 2 + 1.8 ,z:0, ease: Power3.easeOut})

       
    }


    const scrollToScreen2 = () => {

        //Model
        TweenMax.to(model.current.position, 2 ,{x: 3, y:0 ,z:0, ease: Power3.easeOut})
        TweenMax.to(model.current.rotation, 2 ,{x: 0, y: Math.PI * 2 - 1 ,z:0, ease: Power3.easeOut})

     

    }

    const scrollToScreen3 = () => {
        //Model
        TweenMax.to(model.current.position, 2 ,{x: 0, y:3 ,z:-1, ease: Power3.easeOut})
        TweenMax.to(model.current.rotation, 2 ,{x: Math.PI * 3.75, y: 0 ,z:0, ease: Power3.easeOut})

     
    }

    const scrollToScreen4 = () => {

        //Model
        TweenMax.to(model.current.position, 2 ,{x: 6, y: 0 ,z: -3, ease: Power3.easeOut})
        TweenMax.to(model.current.rotation, 2 ,{x: Math.PI, y: Math.PI * 1.5, z: Math.PI / 2, ease: Power3.easeOut})

   
    }

    const scrollToScreen5 = () => {
        TweenMax.to(model.current.position, 2 ,{x: -6, y: 3 ,z: -3, ease: Power3.easeOut})
        TweenMax.to(model.current.rotation, 2 ,{x: Math.PI*1.25 +1, y: Math.PI * 1.45, z: Math.PI + 1, ease: Power3.easeOut})

   
    }
    
    // const onNavClick =(e)=>{
    //   switch(e.eventObject.name) {
    //       case 'architecture': scrollToScreen1(); break
    //       case 'photography': scrollToScreen2(); break
    //       case '3dprint': scrollToScreen3(); break
    //       case 'newsblog': scrollToScreen4(); break
    //       case 'graphicart': scrollToScreen5(); break
    //   }
    // }
    useTexture.preload('/model/matcap_12.png')
    const matcap = useTexture('/model/matcap_12.png')
    return (

        <group>
            <Model 
                ref={model}
                {...modelProps} 
                onClick = {scrollToScreen0}
            />
{/* 
            <mesh>
               <boxBufferGeometry args={[2,2,2]}/>
               <meshNormalMaterial />
           </mesh> */}
           

        </group>
    )
}
    

export default MyScene





