// import ReactDOM from 'react-dom'
import {TweenMax , Power3} from 'gsap';

import React, { Suspense, useRef, useState, useEffect } from 'react'

import { Canvas,  useThree } from 'react-three-fiber'

import { EffectComposer, DepthOfField, Bloom, Noise, Vignette , Glitch } from '@react-three/postprocessing'

import { OrbitControls } from '@react-three/drei'
import { useGLTF,useTexture, Text, MeshWobbleMaterial} from '@react-three/drei'


import MyScene from './MyScene'
import Model from './Model'






const Background =() =>{
   


    return (

    <Canvas className="backgroundCanvas">
        <color attach="background" args={["#050505"]} />
        <ambientLight/>
        <Suspense fallback={null}>
           <MyScene />
           {/* <Model /> */}
           
        </Suspense>


        <OrbitControls />
        
        <EffectComposer>
                <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={480} />
                {/* <Bloom luminanceThreshold={0.9} luminanceSmoothing={0.6} height={300} /> */}
                <Noise opacity={0.05} />
                <Vignette eskil={false} offset={0.1} darkness={1.1} />
      </EffectComposer>
    </Canvas>
    )
}
    

export default Background





